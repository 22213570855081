<template>
  <div id="footer" class="footer d-flex flex-column justify-content-between">
    <div>
      <div>
        <div class="container-footer">
          <h2 class="bold text-uppercase display-6 mt-4">
            {{ $t("partners") }}
          </h2>
        </div>
        <PartnerCarousel />
      </div>
      
      <div class="container-footer">
        <div class="mt-5">
          <div class="row">
            <p class="bold text-uppercase display-6">
              {{ $t("impressum") }}
            </p>

            <div class="col-lg-12 d-flex impressum">
              <div class="">
                <div class="impressum__item">
                  <p>{{ $t('impressum1') }}</p>
                </div>
                <div class="impressum__item">
                  <p>{{ $t('impressum2') }}</p>
                </div>
                <div class="impressum__item">
                  <p>{{ $t('impressum3') }}</p>
                </div>
                <div class="impressum__item">
                  <p>{{ $t('impressum4') }}</p>
                </div>
              </div>
              <div class="impressum__right">
                <div class="impressum__item">
                  <p>{{ $t('impressum5') }}</p>
                </div>
                <div class="impressum__item">
                  <p>{{ $t('impressum6') }}</p>
                </div>
                <div class="impressum__item">
                  <p>{{ $t('impressum7') }}</p>
                </div>
                <div class="impressum__item">
                  <p>{{ $t('impressum8') }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Small screens socials and scroll to top -->
    <div :class="['d-lg-none', 'd-flex align-items-end', 'px-4', 'pb-4']">
      <div class="me-auto">
        <a
          href="https://www.facebook.com/ppmhp/"
          target="_blank"
          rel="external"
          style="z-index: 1000;"
        >
          <img
            class="image image--animate"
            :src="facebook"
            alt="Facebook"
            width="30"
          />
        </a>

        <a
          href="https://www.youtube.com/channel/UC10rJeHJHvmD2CvfYv2FZRw"
          target="_blank"
          rel="external"
          style="z-index: 1000;"
        >
          <img
            class="image image--animate px-2"
            :src="youtube"
            alt="YouTube"
            width="45"
          />
        </a>
      </div>

      <img
        class="scroll-to-top-icon"
        :src="scrollToTopIcon"
        alt="Scroll to top"
        @click="scrollToTop"
      />
    </div>

    <!-- Large screens socials and scroll to top -->
    <div class="d-none d-lg-block">
      <div class="socials py-4">
        <div
          class="
            container-fluid
            d-flex
            flex-row
            align-items-start
            justify-content-start
          "
        >
          <a
            href="https://www.facebook.com/ppmhp/"
            target="_blank"
            rel="external"
            style="z-index: 1000;"
          >
            <img
              class="image image--animate"
              :src="facebook"
              alt="Facebook"
              width="30"
            />
          </a>

          <a
            href="https://www.youtube.com/channel/UC10rJeHJHvmD2CvfYv2FZRw"
            target="_blank"
            rel="external"
            style="z-index: 1000;"
          >
            <img
              class="image image--animate px-2"
              :src="youtube"
              alt="YouTube"
              width="45"
            />
          </a>
          
          <img
            class="scroll-to-top-icon"
            @click="scrollToTop"
            :src="scrollToTopIcon"
            alt="Scroll to top"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PartnerCarousel from "./PartnerCarousel.vue";

export default {
  props: {
    observer: null,
  },

  components: {
    PartnerCarousel,
  },

  data() {
    return {
      logo: require("@/assets/logo/logo.svg"),
      scrollToTopIcon: require("@/assets/icons/scroll_to_top.svg"),
      facebook: require("@/assets/icons/socials/facebook.svg"),
      instagram: require("@/assets/icons/socials/instagram.svg"),
      tiktok: require("@/assets/icons/socials/tiktok.svg"),
      youtube: require("@/assets/icons/socials/youtube.svg"),
    };
  },

  mounted() {
    this.observer.observe(this.$el);
  },

  computed: {
    isIphone() {
      if (/iPhone/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    scrollToTop() {
      this.$emit("activePageChanged", "home");
    },
  },
};
</script>