export default {
  // Home
  title: "Tempus Possibilità",
  description: "Nell’ambito del progetto TEMPUS, viene organizzata la prima mostra FLUXES per incoraggiare lo sviluppo di nuovi prodotti e servizi legati al patrimonio. Tale evento vuole dimostrare il potenziale d’uso del patrimonio delle città pilota, così come le migliori pratiche e i vantaggi comprovati derivanti dallo sfruttamento dei beni culturali in tutto il mondo.",
  read_more: "Leggi di più",

  // Header
  cities: "Città",

  // Benefits
  previous: "Precedente",
  next: "Prossimo",

  benefit_home_title: "BENEFICI DATI DAL PATRIMONIO CULTURALE",
  benefit_home_description: "Negli ultimi decenni è cambiato il rapporto con i beni culturale, che non sono più un peso per le finanze pubbliche, ma piuttosto fonti di molti benefici che vanno oltre il mero sfruttamento per fini turistici: <br><br>· vantaggio economico - il patrimonio culturale genera posti di lavoro ed entrate tributarie ed è una delle motivazioni principali che spingono le persone alla visita di un luogo <br>· sociale - il patrimonio culturale promuove l’integrazione, l’inclusione, la coesione e la collaborazione <br>· ambientale - la conservazione e lo sfruttamento del patrimonio culturale sono fondamentali per uno sviluppo sostenibile dell’ambiente",

  benefit_1_title: "Indicatore economico",
  benefit_2_title: "Indicatore sociale",
  benefit_3_title: "Indicatore culturale",

  benefit_0_example_title: "",
  benefit_1_example_title: "Pont Du Gard",
  benefit_2_example_title: "Borgund Stave",
  benefit_3_example_title: "Tate Modern",
  benefit_4_example_title: "Mechelen",

  benefit_1_example_description: "È stato valutato che il numero complessivo di visitatori (1,1 milioni l’anno) genera 1.209 posti di lavoro. Le spese di mantenimento annuali ammontano a 7 milioni di euro, mentre dai servizi offerti ai visitatori (ristoranti, parcheggi, souvenir, ingressi) rientrano 3,6 milioni di euro. L’introito indiretto viene stimato come pari a 135 milioni di euro (denaro speso dai visitatori al di fuori della località in senso stretto). Si stima un introito tributario pari a 21,5 milioni di euro.",
  benefit_2_example_description: "La chiesa di Borgund Stave (Norvegia) genera un ritorno del 628,5% sui costi di manutenzione annuali con le sole entrate fiscali.",
  benefit_3_example_description: "La riconversione di una ex centrale elettrica nel museo di arte contemporanea Tate Modern è un esempio di utilizzo fruttuoso del patrimonio industriale. In un solo anno, la Tate Modern è diventata tra le 3 attrazioni più visitate della Gran Bretagna. Oltre ai 300 nuovi posti di lavoro generati dalla sua costruzione, 500 posti di lavoro diretti e 1.800 posti di lavoro indiretti sono sorti nel settore dell’ospitalità in relazione a questa istituzione culturale.  Inoltre, varie decine di milioni di sterline vengono generate anche attraverso l’effetto di ricaduta (spillover effect).",
  benefit_4_example_description: "Uno studio sull’impatto socio-economico dei beni culturali nella città di Mechelen (Belgio) ha dimostrato che il patrimonio e la sua conservazione fruttuosa sono fattori chiave per migliorare la qualità della vita dei cittadini. In un sondaggio rivolto ai cittadini, l’84% di essi ha infatti identificato tale patrimonio come il maggior contributo alla mutazione in positivo dell’immagine della città.",

  // Cities 
  potentials: "Potenziali",

  city_1_name: "Rijeka",
  city_2_name: "Solin",
  city_3_name: "Ravenna",

  city_1_about: "La posizione di Fiume, situata nell’angolo più settentrionale del golfo del Quarnaro e attorniata da un entroterra collinare, ha inevitabilmente spinto la città a rivolgere la propria attenzione al mare e al mondo della nautica. Le attrazioni fiumane, nel contesto di questo progetto, sono testimonianze proprio del carattere marino, portuale e industriale della città. La gran parte di esse affonda le proprie radici nel XIX e XX secolo, ovvero nel periodo che ha rappresentato il culmine dello sviluppo cittadino.",
  city_2_about: "Il fiume Jadro e la vicinanza al Mediterraneo sono stati elementi determinanti per la storia dell’area di Solin (in italiano, Salona), dalla fondazione dell’antica metropoli che portava il nome romano di Salona fino allo sviluppo tecnologico del XX secolo dovuto perlopiù alla presenza di cave e cementifici. Gli aspetti dell’area che maggiormente interessano questo progetto sono proprio le testimonianze di tale sviluppo che conduce dall’antichità fino all’industrializzazione del XX secolo.",

  //City 1 potentials - RIJEKA
  city_1_potential_1_title: "Magazzini",
  city_1_potential_1_description: "Il complesso dei magazzini portuali risalente all’inizio del XX secolo e situato nell’area occidentale del porto è stato definito Metropolis dai restauratori, in quanto ricorda la scenografia dell’omonimo classico cinematografico di Fritz Lang. In particolare, i magazzini inutilizzati Metropolis potrebbero essere convertiti in spazi pubblici e residenziali, liberando l’energia passiva degli edifici.",
  city_1_potential_1_type: "",

  city_1_potential_2_title: "Hotel Emigranti",
  city_1_potential_2_description: "La linea Fiume - New York venne fondata nel 1903. Data la scarsità di alloggi disponibili per i migranti in transito, nell’allora Via Valosca (oggi Ulica Milutina Brača), venne aperto l’Hotel Emigranti. Se restaurata, tale struttura potrebbe costituire un punto importante per Fiume e svolgere ancora oggi la propria funzione originaria, ovvero offrire ospitalità ai migranti, i cui numeri in città sono in costante aumento.",
  city_1_potential_2_type: "",

  city_1_potential_3_title: "Hartera",
  city_1_potential_3_description: "Per l’area della cartiera, è stato realizzato uno studio che propone lo sviluppo del Centro sociale e culturale Hartera, il quale vede l’unione di tre organi dipendenti e indivisibili tra loro: la vecchia sala produttiva Parafinka come luogo di imprenditoria creativa, l’alloggio residenziale-alberghiero nel complesso dei vecchi edifici e la sala Marganovo come centro di arti performative, conferenze e attività amministrative. (Turato, Križaj Leko)",
  city_1_potential_3_type: "",

  city_1_potential_4_title: "Patrimonio socialista",
  city_1_potential_4_description: "Nel periodo della ex Jugoslavia, Fiume conobbe uno sviluppo rapido basato su forti aziende nazionali come quelle navali, tra cui spiccano la Jugolinija e il cantiere navale 3 Maggio, due istituzioni che molti fiumani legano alla propria storia familiare. È necessario approfondire e interpretare tale patrimonio, nonché presentarlo al pubblico; senza il contributo di quest’ultimo, infatti, non è possibile conservarlo, né tantomeno sfruttarlo.",
  city_1_potential_4_type: "",

  city_1_potential_5_title: "Museo dell’industria",
  city_1_potential_5_description: "Il patrimonio culturale di Fiume appartiene a varie epoche, ma la sua parte più consistente risale sicuramente agli ultimi due secoli. Si potrebbe dire che il patrimonio fiumano più significativo è quello di origine industriale e portuale e, come tale, andrebbe presentato in un museo specializzato che potrebbe occupare uno dei beni culturali al momento inutilizzati.",
  city_1_potential_5_type: "",

  city_1_potential_6_title: "Molo longo",
  city_1_potential_6_description: "Data la posizione del porto fiumano, i cittadini non hanno pressoché alcun accesso alla riva nel centro città, ad eccezione della costruzione frangiflutti. Dato il suo carattere di grande utilità, sul frangiflutti non è consentito attuare grandi interventi, ma è possibile, per esempio, collocare delle panchine, continuare la decorazione del muro e porre dei pannelli informativi.",
  city_1_potential_6_type: "",

  //City 2 potentials - SOLIN
  city_2_potential_1_title: "Sito archeologico di Salona",
  city_2_potential_1_description: "L’antica Salona era la capitale della provincia romana della Dalmazia e il suo simbolo per eccellenza è certamente l’anfiteatro. Il sito è ora aperto ai visitatori e può essere sfruttato anche per eventi culturali, turistici e sportivi, nonché per rappresentazioni di arte urbana.",
  city_2_potential_1_type: "",

  city_2_potential_2_title: "Gradina",
  city_2_potential_2_description: "Una chiesa dalla pianta inusuale, costruita sopra i resti di epoca romana, sorge oggi all’interno di una fortezza medioevale. Nel periodo estivo, il sito viene usato per il festival culturale cittadino che è diventato un evento ormai parte delle tradizioni del luogo.",
  city_2_potential_2_type: "",

  city_2_potential_3_title: "Centro storico di Solin",
  city_2_potential_3_description: "Il centro storico di Solin si trova sul lato orientale dell’antica Salona ed è anche uno dei centri altomedievali più importanti di tutta la Croazia. Durante tutto l’anno, viene usato per molteplici eventi di carattere turistico e culturale.",
  city_2_potential_3_type: "",

  city_2_potential_4_title: "Penisola Vranjic",
  city_2_potential_4_description: "Questa piccola penisola abitata presenta una riva ricca di vita ed è collegata alla terraferma per mezzo di una strada rialzata. Tra le vie di questo luogo pittoresco, chiamato anche “piccola Venezia”, vengono spesso organizzati eventi locali.",
  city_2_potential_4_type: "",

  city_2_potential_5_title: "Centro culturale Majdan",
  city_2_potential_5_description: "Il Centro è stato fondato nel 1954, innalzando il livello locale dell’offerta culturale e sociale. Parte dell’edificio è oggi usato per fini sportivi; tuttavia, la piazza antistante alla struttura potrebbe essere utilizzata per vari eventi pubblici correlati allo scopo della fondazione del Centro stesso.",
  city_2_potential_5_type: "",

  city_2_potential_6_title: "Area industriale di Majdan",
  city_2_potential_6_description: "Il quinto cementificio della Dalmazia risale al 1908 e si trova nell’area di Majdan. All’epoca, c’era una forte necessità di dare alloggio alla forza lavoro e per questo vennero costruiti vari edifici tutti identici nell’area circostante. L’area limitrofa potrebbe essere usata per ospitare eventi locali volti a ridare vita al quartiere.",
  city_2_potential_6_type: "",

  city_2_potential_7_title: "Area industriale di Sv. Kajo",
  city_2_potential_7_description: "Con la fondazione del cementificio di Sv. Kajo e il suo successivo sviluppo, vennero costruiti molti alloggi, assieme a strutture destinate ad ospitare uffici e addirittura un piccolo cinema; soprattutto quest’ultimo potrebbe essere restaurato e sfruttato per ospitare eventi locali o per fornire uno spazio di aggregazione ad associazioni e club.",
  city_2_potential_7_type: "",

  // Footer
  partners: "Partner",
  impressum: "Impressum",
  scroll_to_top: "Torna in cima",

  // Impressum items
  impressum1: 'Autore della mostra: Luka Strašek',
  impressum2: 'Autori dei testi: Ivana Tokić, Luka Strašek',
  impressum3: 'Foto: Petar Fabijan, Archivio del Dipartimento di Conservazione di Spalato, Città di Solin, Archivio del Museo Marittimo e Storico del Litorale Croato, Fiume',
  impressum4: 'Autore degli animazioni: Luka Strašek',
  impressum5: 'Sviluppo del sito web: Exevio d.o.o.',
  impressum6: 'Traduzione e revisione: Integra d.o.o.',
  impressum7: 'Fonti: Centro per il Patrimonio Industriale Fiume, Europa Nostra, www.idisturato.com',
  impressum8: '',
}