<template>
  <div class="w-100 mt-5">
    <div class="marquee marquee--hover-pause d-md-flex d-xl-none">
      <div class="marquee__content">
        <a
          v-for="(item, index) in images"
          :key="index"
          :href="item.url"
          rel="external"
          target="_blank"
        >
          <img :src="item.image" />
        </a>
      </div>

      <div aria-hidden="true" class="marquee__content">
        <a
          v-for="(item, index) in images"
          :key="index"
          :href="item.url"
          rel="external"
          target="_blank"
        >
          <img :src="item.image" />
        </a>
      </div>
    </div>

    <div class="container">
      <div class="d-none d-xl-flex flex-wrap partners-desktop">
        <a
          v-for="(item, index) in images"
          :key="index"
          :href="item.url"
          rel="external"
          target="_blank"
          class="partners-desktop__item"
        >
          <img :src="item.image" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {
          image: require("@/assets/partners/rda-porin.png"),
          url: "https://porin.hr/",
        },
        {
          image: require("@/assets/partners/rerasd.png"),
          url: "http://www.rera.hr/",
        },
        {
          image: require("@/assets/partners/ppmhp.png"),
          url: "https://ppmhp.hr/",
        },
        {
          image: require("@/assets/partners/itc-cnr.png"),
          url: "https://www.itc.cnr.it",
        },
        {
          image: require("@/assets/partners/university-of-bologna.png"),
          url: "https://www.unibo.it/",
        },
        {
          image: require("@/assets/partners/grad-solin.png"),
          url: "https://www.solin.hr/",
        },
        {
          image: require("@/assets/partners/certimac.png"),
          url: "https://certimac.it/",
        },
        {
          image: require("@/assets/partners/grad-rijeka.png"),
          url: "https://www.rijeka.hr/",
        },
        {
          image: require("@/assets/partners/cna.jpg"),
          url: "https://www.ra.cna.it/",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.marquee {
  --gap: 120px;
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  user-select: none;
  gap: var(--gap);

  @media (max-width: 600px) {
    height: 100px;
  }

  @media (max-height: 600px) {
    max-height: 50px;
  }
}

@media (max-width: 600px) {
  .marquee {
    --gap: 60px;
  }
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: var(--gap);
  min-width: 100%;
  height: 100%;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

.marquee__content {
  animation: scroll 20s linear infinite;
}

.marquee__content img {
  max-width: 200px;
  max-height: 200px;
  height: 100%;
  object-fit: contain;
  opacity: 0.6;
  transition: all 0.25s ease-in-out;

  @media (max-width: 992px) {
    opacity: 1;
  }

  @media (max-width: 600px) {
    max-width: 100px;
    max-height: 100px;
  }

  @media (max-height: 600px) {
    max-height: 50px;
  }

  &:hover {
    opacity: 1;
  }

  @media (max-height: 900px) {
    max-width: 170px;
  }
}

/* Pause on hover */
.marquee--hover-pause:hover .marquee__content {
  animation-play-state: paused;
}

/* Enable position absolute animation on the duplicate content (last-child) */
.enable-animation .marquee--pos-absolute .marquee__content:last-child {
  animation-name: scroll-abs;
}

@keyframes scroll-abs {
  from {
    transform: translateX(calc(100% + var(--gap)));
  }
  to {
    transform: translateX(0);
  }
}
</style>