<template>
  <div id="benefits" class="benefits">
    <div class="gradient h-100 w-100"></div>

    <div v-for="benefit in benefits" :key="benefit.id">
      <video
        v-if="benefit.picture.includes('mp4')"
        :class="[
          activeBenefit !== benefit.id ? 'hidden' : '',
          'image',
          'h-100',
          'w-100',
          'benefitEl',
        ]"
        :src="require(`@/assets/benefits/${benefit.picture}`)"
        alt=""
        autoplay
        muted
        playsinline
      />

      <img
        v-else
        :class="[
          activeBenefit !== benefit.id ? 'hidden' : '',
          'image',
          'h-100',
          'w-100',
          'benefitEl',
        ]"
        :src="require(`@/assets/benefits/${benefit.picture}`)"
        alt=""
      />
    </div>

    <div
      class="h-100 d-flex flex-column justify-content-center align-items-center"
    >
      <div class="content container-fluid container-fluid--start ptb-80">
        <Benefit
          v-for="benefit in benefits"
          :key="benefit.id"
          v-show="activeBenefit === benefit.id"
          :benefit="benefit"
          :activeBenefit="activeBenefit"
        />
      </div>
      <BenefitsControls
        :benefits="benefits"
        :activeBenefit="activeBenefit"
        @activeBenefitChanged="changeActiveBenefit"
        :activePageId="activePageId"
      />
    </div>
  </div>
</template>

<script>
import Benefit from "./Benefit.vue";
import BenefitsControls from "./BenefitsControls.vue";
import { videoInBenefit } from "../../video.js";

import benefits from "../../assets/benefits";

export default {
  components: {
    Benefit,
    BenefitsControls,
  },

  data() {
    return {
      benefits,
      activeBenefit: 0,
      preloadedImages: [],
    };
  },

  props: {
    observer: null,
    activePageId: {
      typeof: String,
      default: "",
    },
  },

  mounted() {
    this.observer.observe(this.$el);

    for (const benefit of this.benefits) {
      let image = new Image();
      image.src = require(`@/assets/benefits/${benefit.picture}`);

      this.preloadedImages.push(image);
    }
  },

  methods: {
    changeActiveBenefit(benefitId) {
      this.activeBenefit = benefitId;

      videoInBenefit(this.activeBenefit);
    },
  },
};
</script>
