import en from '@/plugins/i18n/locales/en'
import hr from '@/plugins/i18n/locales/hr'
import it from '@/plugins/i18n/locales/it'
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'hr', // set locale
  fallbackLocale: 'hr',
  silentTranslationWarn: true,
  messages: {
    en,
    hr,
    it,
  },
})

const loadedLanguages = ['en'] 

function setI18nLanguage(lang) {
  i18n.locale = lang

  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ '@/plugins/i18n/locales/' + lang + '.js').then(msgs => {
    i18n.setLocaleMessage(lang, msgs.default)
    loadedLanguages.push(lang)

    return setI18nLanguage(lang)
  })
}
