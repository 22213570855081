const benefits = [
  {
    id: 0,
    picture: "video-benefits.mp4",
    translation_key_example_title: "benefit_0_example_title",
    translation_key_example_description: "benefit_home_description",
  },
  {
    id: 1,
    picture: "pont-du-gard.jpg",
    translation_key_example_title: "benefit_1_example_title",
    translation_key_example_description: "benefit_1_example_description",
  },
  {
    id: 2,
    picture: "stave-church.mp4",
    translation_key_example_title: "benefit_2_example_title",
    translation_key_example_description: "benefit_2_example_description",
  },
  {
    id: 3,
    picture: "tate-modern2.jpg",
    translation_key_example_title: "benefit_3_example_title",
    translation_key_example_description: "benefit_3_example_description",
  },
  {
    id: 4,
    picture: "mechelen.jpg",
    translation_key_example_title: "benefit_4_example_title",
    translation_key_example_description: "benefit_4_example_description",
  },
]

export default benefits