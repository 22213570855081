<template>
  <div class="cities">
    <div
      class="wrapper"
      v-for="city in cities"
      :key="city.name"
      :id="'wrapper-city-' + city.id"
    >
      <City
        :id="'city-' + city.id"
        :city="city"
        :observer="observer"
        :activePageId="activePageId"
      />
    </div>
  </div>
</template>

<script>
import City from "./City.vue";

import cities from "../../assets/cities";

export default {
  components: {
    City,
  },

  props: {
    isMobile: {
      typeof: Boolean,
      require: true,
    },
    activePageId: {
      typeof: String,
      default: "",
    },
    observer: null,
  },

  data() {
    return {
      cities,
    };
  },
};
</script>