export default {
  // Home
  title: "Tempus Mogućnosti",
  description: "U sklopu projekta TEMPUS postavljena je prva izložba FLUXES kako bi potaknula razvoj novih proizvoda i usluga temeljenih na baštini. Ova izložba predstavlja mogućnosti korištenja baštine pilot-gradova, kao i najbolje prakse te dokazane prednosti korištenja baštine u cijelom svijetu.",
  read_more: "Pročitaj više",

  // Header
  cities: "Grad",

  // Benefits
  previous: "Prethodni",
  next: "Sljedeći",

  benefit_home_title: "Dobrobiti kulturne baštine",
  benefit_home_description: "Posljednjih desetljeća mijenja se odnos prema kulturnoj baštini te ona više nije teret javnim financijama već se priznaju njezine višestruke dobrobiti osim iskorištavanja u svrhu turizma: <br><br>· gospodarska – kulturna baština generator je radnih mjesta i poreznih prihoda te je primarna motivacija posjeta <br>· društvena – kulturna baština pogoduje integraciji, uključivosti, koheziji i sudjelovanju <br>· okolišna – očuvanje i korištenje kulturne baštine pridonosi održivom razvoju okoliša",

  benefit_1_title: "Ekonomski indikator",
  benefit_2_title: "Društveni indikator",
  benefit_3_title: "Kulturalni indikator",

  benefit_0_example_title: "",
  benefit_1_example_title: "Pont Du Gard",
  benefit_2_example_title: "Borgund Stave",
  benefit_3_example_title: "Tate Modern",
  benefit_4_example_title: "Mechelen",
  benefit_5_example_title: "Radna mjesta",

  benefit_1_example_description: "Procijenjeno je da ukupan broj posjetitelja (1,1 milijun godišnje) generira 1209 radnih mjesta. Troškovi godišnjeg održavanja iznose 7 milijuna eura, dok od usluga posjetiteljima (restorani, parkirališta, muzejske suvenirnice, ulaznice) zarađuje 3,6 milijuna eura. Neizravni utjecaj procjenjuje se na 135 milijuna eura (troškovi posjetitelja izvan područja lokaliteta. Porezni prihod procjenjuje se na 21,5 milijuna eura.",
  benefit_2_example_description: "Crkva Borgund Stave (Norveška) samo od prihoda od poreza generira 628,5 % povrata godišnjeg troška održavanja.",
  benefit_3_example_description: "Pretvaranje nekadašnje elektrane u muzej suvremene umjetnosti Tate Modern primjer je uspješnog korištenja industrijske baštine. U samo jednoj godini, Tate Modern postao je treća najposjećenija atrakcija u Britaniji. Uz 300 novih radnih mjesta u izgradnji, stvoreno je izravnih 500 te 1800 neizravnih radnih mjesta u sektoru ugostiteljstva. Usto, generirani su deseci milijuna funti prihoda kroz efekt prelijevanja (eng. spillover effect).",
  benefit_4_example_description: "Studija socioekonomskog utjecaja baštine u gradu Mechelenu (Belgija) pokazala je da su baština i njezino uspješno očuvanje ključni faktori u povećanju kvalitete života građana. U anketi je 84 % građana navelo baštinu kao najveći doprinos promjeni imidža grada.",
  benefit_5_example_description: "Studija Svjetske banke iz 2001. godine pokazuje da je na svaki uloženi milijun američkih dolara u obnovu zgrada otvoreno 31,3 radno mjesto, dok jednako ulaganje u proizvodnim industrijama donosi samo 21,3 radno mjesto. Procjenjuje se da sektor kulturne baštine stvara do 26,7 neizravnih radnih mjesta za svako izravno radno mjesto, puno više od primjerice autoindustrije s kvocijentom od samo 6,3.",

  // Cities 
  potentials: "Potencijali",

  city_1_name: "Rijeka",
  city_2_name: "Solin",
  city_3_name: "Ravenna",

  city_1_about: "Položaj Rijeke smještene u duboko uvučenom najsjevernijem kutu Kvarnerskog zaljeva, uz njegovo brdovito zaleđe, neminovno je doveo do okretanja grada prema moru i pomorskoj trgovini, odnosno brodarstvu. U središtu ovoga projekta su riječke znamenitosfti koje svjedoče o pomorskom i lučkom, ali i industrijskom karakteru grada. Većina ih je iz razdoblja 19. i 20. stoljeća što predstavlja vrhunac razvoja Rijeke.",
  city_2_about: "Rijeka Jadro i otvorenost prema Mediteranu odredili su povijest solinskog kraja, od uspona antičke metropole Salone pa sve do tehnološkog razvoja Solina u 20. stoljeću koji se uvelike temeljio na tvornicama cementa i kamenolomima. Solinske znamenitosti u središtu ovog projekta oni su spomenici koji svjedoče razvoju grada od antičkih vremena do industrijalizacije u 20. stoljeću.",

  // City 1 potentials - RIJEKA
  city_1_potential_1_title: "Skladišta",
  city_1_potential_1_description: "Kompleks lučkih skladišta s početka 20. stoljeća na zapadnom dijelu luke konzervatori su nazvali Metropolis jer podsjeća na scenografiju filmskog klasika Fritza Langa. Neiskorištena skladišta poput Metropolisa mogu se prenamijeniti u stambene i javne prostore oslobađajući pasivnu energiju građevine.",
  city_1_potential_1_type: "",

  city_1_potential_2_title: "Hotel Emigranti",
  city_1_potential_2_description: "Linija Rijeka – New York uspostavljena je 1903. godine. Zbog manjka smještajnih kapaciteta za migrante u tranzitu, u tadašnjoj Via Volosca (danas Ulica Milutina Brača) podignut je Hotel „Emigranti”. Cjelovito obnovljen, bio bi važna znamenitost Rijeke te bi mogao služiti svojoj prvotnoj namjeni – smještaju migranata kojih je u Rijeci sve više.",
  city_1_potential_2_type: "",

  city_1_potential_3_title: "Hartera",
  city_1_potential_3_description: "Za područje tvornice papira izrađena je studija koja predlaže razvoj Društveno-kulturnog centra Hartera kao spoj triju zasebnih, ali ovisnih i nedjeljivih organa – stare proizvodne hale Parafinka kao prostora kreativnog poduzetništva, stambeno-hotelskog smještaja u kompleksu starih stambenih zgrada te dvorane Marganovo kao centra izvedbenih umjetnosti, konferencije i administracije. (Turato, Križaj Leko)",
  city_1_potential_3_type: "",

  city_1_potential_4_title: "Baština socijalizma",
  city_1_potential_4_description: "U razdoblju bivše SFRJ Rijeka je doživjela ubrzani razvoj na temelju jakih domaćih poduzeća poput brodarske Jugolinije ili brodograditeljskog 3. Maja uz koje mnogi Riječani vežu svoju obiteljsku povijest. Baštinu toga razdoblja potrebno je istražiti, interpretirati te predstaviti javnosti bez čijeg uključivanja nije moguće njezino očuvanje i korištenje.",
  city_1_potential_4_type: "",

  city_1_potential_5_title: "Muzej industrije",
  city_1_potential_5_description: "Kulturna baština Rijeke pripada različitim razdobljima, no njezin najveći dio potječe iz posljednjih dvaju stoljeća. Moglo bi se reći da je najvažnija baština Rijeke industrijska i lučka baština te bi kao takva trebala biti predstavljena u specijaliziranom muzeju koji bi okupirao prostor jednog od neiskorištenih baštinskih objekata.",
  city_1_potential_5_type: "",

  city_1_potential_6_title: "Molo longo",
  city_1_potential_6_description: "Zbog smještaja riječke luke građani Rijeke gotovo da nemaju pristup obali u središtu grada, osim riječkog lukobrana. Zbog njegova strogo utilitarnog karaktera na lukobranu nisu mogući veći zahvati, no moguće je postaviti klupe za brojne šetače, dovršiti likovno uređenje zida lukobrana te postaviti interpretacijske ploče.",
  city_1_potential_6_type: "",

  // City 2 potentials - SOLIN
  city_2_potential_1_title: "Arheološko nalazište Salona",
  city_2_potential_1_description: "Antička Salona bila je glavni grad rimske provincije Dalmacije (Dalmatia). Najimpozantnija građevina u antičkoj Saloni svakako je amfiteatar. Danas je ovo nalazište otvoreno za posjetitelje i može se koristiti za kulturna, turistička i sportska događanja, ali i za urbane umjetničke akcije.",
  city_2_potential_1_type: "",

  city_2_potential_2_title: "Gradina",
  city_2_potential_2_description: "Ova crkva neobičnog tlocrta izgrađena je na ostatcima koji datiraju iz rimskih vremena, a danas se nalazi u sklopu srednjovjekovne utvrde. Ljeti se ovdje održava Solinsko kulturno ljeto, festival koji je i sam postao dijelom kulturne tradicije svoga grada.",
  city_2_potential_2_type: "",

  city_2_potential_3_title: "Povijesna jezgra Solina",
  city_2_potential_3_description: "Povijesna jezgra Solina smještena je na istočnoj strani antičke Salone i jedno je od najvažnijih ranosrednjovjekovnih središta hrvatske kulture. Tijekom čitave godine tu se održavaju različita mjesna turistička i kulturna događanja.",
  city_2_potential_3_type: "",

  city_2_potential_4_title: "Poluotok Vranjic",
  city_2_potential_4_description: "Vranjic je mali naseljeni poluotok s aktivnom rivom, povezan nasipom s kopnom. Lokalna događanja često se organiziraju na ulicama ovog pitoresknog mjesta, lokalno nazivanog \"Malom Venecijom\".",
  city_2_potential_4_type: "",

  city_2_potential_5_title: "Kulturni centar Majdan",
  city_2_potential_5_description: "Centar je izgrađen 1954. čime je podignut standard kulturnih i društvenih funkcija u Majdanu. Dio zgrade danas se koristi za sportske namjene. Međutim, trg ispred zgrade može se koristiti za različita javna događanja, u skladu s izvornom funkcijom zgrade.",
  city_2_potential_5_type: "",

  city_2_potential_6_title: "Tvorničko naselje Majdan",
  city_2_potential_6_description: "Peta dalmatinska tvornica cementa izgrađena je 1908. na području Majdana. Zbog velike potrebe za smještajem radne snage, sagrađeno je nekoliko tipološki gotovo identičnih zgrada. Okolni prostor može se koristiti za mjesna događanja kako bi se ovaj dio grada revitalizirao.",
  city_2_potential_6_type: "",

  city_2_potential_7_title: "Tvorničko naselje Sv. Kajo",
  city_2_potential_7_description: "Uz osnivanje tvornice cementa Sv. Kajo i njezin kasniji razvoj izgrađeno je i nekoliko stambenih zgrada uz poslovne zgrade, pa čak i malo kino koje se može obnoviti i koristiti za mjesna kulturna događanja i kao prostor za mjesne udruge i klubove.",
  city_2_potential_7_type: "",

  // Footer
  partners: "Partneri",
  impressum: "Impressum",
  scroll_to_top: "Povratak",

  // Impressum items
  impressum1: 'Autor izložbe: Luka Strašek',
  impressum2: 'Autori tekstova: Ivana Tokić, Luka Strašek',
  impressum3: 'Fotografije: Petar Fabijan, Arhiv Konzervatorskog odjela u Splitu, Grad Solin, Arhiv Pomorskog i povijesnog muzeja Hrvatskog primorja Rijeka',
  impressum4: 'Autor animacija: Luka Strašek',
  impressum5: 'Razvoj web stranice: Exevio d.o.o.',
  impressum6: 'Prijevod i lektura: Integra d.o.o.',
  impressum7: 'Izvori: Centar za industrijsku baštinu Rijeka, Europa Nostra',
  impressum8: 'Arhiv Konzervatorskog odjela u Splitu, Grad Solin, Pomorski i povijesni muzej Hrvatskog primorja Rijeka',
}