var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"controls w-100"},[_c('div',{staticClass:"pt-4"},[_c('div',{staticClass:"row align-items-end"},[(_vm.manualPagination)?_c('div',{staticClass:"col d-flex city__arrows"},[_c('div',{staticClass:"prev pointer",on:{"click":function($event){return _vm.changeActivePotential(_vm.activePotential - 1)}}},[_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/icons/previous.svg')}}),_c('p',{staticClass:"control-text button-text"},[_vm._v(" "+_vm._s(_vm.$t("previous"))+" ")])]),_c('div',{staticClass:"desktop-icons justify-content-center"},_vm._l((_vm.potentials),function(potential){return _c('div',{key:potential.id,staticClass:"circle icon icon--animate",class:[
              _vm.activePotential === potential.id
                ? _vm.circleVisible
                : _vm.circleOpacity,
              'pointer',
              'pe-2',
              'toggle-icon',
            ],on:{"click":function($event){return _vm.changeActivePotential(potential.id)}}})}),0),_c('div',{staticClass:"next pointer",on:{"click":function($event){return _vm.changeActivePotential(_vm.activePotential + 1)}}},[_c('img',{staticClass:"icon",attrs:{"src":require('@/assets/icons/next.svg')}}),_c('p',{staticClass:"control-text button-text"},[_vm._v(" "+_vm._s(_vm.$t("next"))+" ")])])]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }