<template>
  <div
    :class="[
      activePotential !== potential.id
        ? 'potential-hidden'
        : 'potential-visible',
      'potential-animation',
    ]"
    class="
      container-fluid container-fluid--city
      potential
      w-100
      justify-content-between
      ptb-80
    "
  >
    <div class="p-lg-0">
      <div class="row">
        <div class="picture-container col-12 col-lg-6">
          <video
            v-if="potential.picture.includes('mp4')"
            :class="`video-player cityEl-${cityId}`"
            :src="potentialImage"
            alt=""
            autoplay
            muted
            playsinline
          />

          <img
            v-else-if="potential.picture.includes('jpg')"
            :class="`picture h-100 w-100 cityEl-${cityId}`"
            :src="potentialImage"
          />
        </div>

        <div class="city__text col-12 col-lg-6 text-lg-start text-lg-start">
          <h2
            class="potential-h2 d-lg-block text-uppercase bold display-5 ls-15"
          >
            {{ cityName }}
          </h2>

          <h3 v-if="potential.id !== 0" class="potential-title mt-4 mb-4 bold">
            {{ $t(potential.translation_key_title) }}
          </h3>

          <p
            :class="[potential.id === 0 ? 'pt-4' : '', 'description']"
            v-html="$t(potential.translation_key_description)"
          ></p>
        </div>
      </div>
    </div>

    <CityControls
      :class="[manualPagination ? 'pt-5' : '']"
      :potentials="potentials"
      :activePotential="activePotential"
      :manualPagination="manualPagination"
      @activePotentialChanged="changeActivePotential"
    />
  </div>
</template>

<script>
import CityControls from "./CityControls.vue";

export default {
  props: {
    cityName: {
      typeof: String,
      default: "",
    },

    potential: {
      typeof: Object,
      default: undefined,
    },

    activePotential: {
      typeof: Number,
    },

    cityId: {
      typeof: Number,
    },

    potentials: {
      typeof: Object,
      default: undefined,
    },

    manualPagination: {
      typeof: Boolean,
      default: true,
    },
  },

  components: {
    CityControls,
  },

  data() {
    return {
      potentialImage: require(`@/assets/cities/${this.cityName}/${this.potential.picture}`),
    };
  },

  methods: {
    changeActivePotential(potentialId) {
      this.$emit("activePotentialChanged", potentialId);
    },
  },
};
</script>
