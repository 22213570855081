var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"benefits",attrs:{"id":"benefits"}},[_c('div',{staticClass:"gradient h-100 w-100"}),_vm._l((_vm.benefits),function(benefit){return _c('div',{key:benefit.id},[(benefit.picture.includes('mp4'))?_c('video',{class:[
        _vm.activeBenefit !== benefit.id ? 'hidden' : '',
        'image',
        'h-100',
        'w-100',
        'benefitEl',
      ],attrs:{"src":require(`@/assets/benefits/${benefit.picture}`),"alt":"","autoplay":"","muted":"","playsinline":""},domProps:{"muted":true}}):_c('img',{class:[
        _vm.activeBenefit !== benefit.id ? 'hidden' : '',
        'image',
        'h-100',
        'w-100',
        'benefitEl',
      ],attrs:{"src":require(`@/assets/benefits/${benefit.picture}`),"alt":""}})])}),_c('div',{staticClass:"h-100 d-flex flex-column justify-content-center align-items-center"},[_c('div',{staticClass:"content container-fluid container-fluid--start ptb-80"},_vm._l((_vm.benefits),function(benefit){return _c('Benefit',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeBenefit === benefit.id),expression:"activeBenefit === benefit.id"}],key:benefit.id,attrs:{"benefit":benefit,"activeBenefit":_vm.activeBenefit}})}),1),_c('BenefitsControls',{attrs:{"benefits":_vm.benefits,"activeBenefit":_vm.activeBenefit,"activePageId":_vm.activePageId},on:{"activeBenefitChanged":_vm.changeActiveBenefit}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }