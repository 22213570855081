<template>
  <div class="container-lg">
    <div class="dots d-flex flex-column" v-if="!mobile">
      <span 
        :class="[activePageId == page ? (page === 'benefits' ? 'selected-benefits' : 'selected') : 'not-selected', 'dot', 'mt-1']"
        v-for="page in pages"
        :key="page"
      ></span>
    </div>
    <div class="dots d-flex flex-column" v-else>
      <span 
        :class="[activePageId == page ? (page === 'benefits' || page === 'city-0'  || page === 'city-1'  ? 'selected-benefits' : 'selected') : 'not-selected', 'dot', 'mt-1']"
        v-for="page in pages"
        :key="page"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activePageId: {
      typeof: String,
      default: '',
    },
  },

  data() {
    return {
      pages: [
        'home',
        'benefits',
        'city-0',
        'city-1',
        // 'city-2',
        'footer',
      ],
      mobile: false
    }
  },

  mounted(){
    if(window.innerWidth < 992){
      this.mobile = true
    }
  }
}
</script>

<style>
.dot {
  height: 0.5em;
  width: 0.5em;
  border-radius: 50%;
  display: inline-block;
}

.not-selected {
  background-color: rgba(206, 206, 206, 0.534);
}

.selected {
  background-color: #212529;
}

.selected-benefits {
  background-color: #ffffff;
}
</style>