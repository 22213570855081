export default {
  // Home
  title: "Tempus Possibilities",
  description: "A part the TEMPUS project, the first FLUXES exhibition is set up to encourage the development of new heritage-based products and services. The exhibition presents the potential uses of heritage in the pilot cities, as well as some best practices and proven benefits of using heritage from all around the world.",
  read_more: "Read more",

  // Header
  cities: "City",

  // Benefits
  previous: "Previous",
  next: "Next",

  benefit_home_title: "BENEFITS OF CULTURAL HERITAGE",
  benefit_home_description: "Over the last few decades, the relationship towards cultural heritage has been changing—it is no longer viewed as a burden on public finances but is rather recognized for its many benefits far exceeding tourism purposes: <br><br>· economic benefits: cultural heritage creates jobs and tax revenue, and it is the prime reason for visits <br>· social benefits: cultural heritage supports integration, inclusion, cohesion and participation <br>· environmental benefits: the conservation and use of cultural heritage contributes to sustainable environment development",

  benefit_1_title: "Economic indicator",
  benefit_2_title: "Social indicator",
  benefit_3_title: "Cultural indicator",

  benefit_0_example_title: "",
  benefit_1_example_title: "Pont Du Gard",
  benefit_2_example_title: "Borgund Stave",
  benefit_3_example_title: "Tate Modern",
  benefit_4_example_title: "Mechelen",

  benefit_1_example_description: "It is estimated that the total number of visitors (1.1 million per year) generates 1,209 jobs. The yearly maintenance costs amount to 7 million euro, while services for the visitors (restaurants, parking lots, museum shops, tickets) bring in 3.6 million euro. Indirect impact is estimated at 135 million euro (visitor expenses outside of the sight area). Tax revenue is estimated at 21.5 million euro.",
  benefit_2_example_description: "The Borgund Stave church in Norway generates—from tax revenue alone—a 628.5% return on the yearly maintenance costs.",
  benefit_3_example_description: "Turning the former electric power station into the Tate Modern museum of contemporary art is a famous example of a successful use of industrial heritage. In a single year, the Tate Modern became the third most visited attraction in Great Britain. Along with 300 jobs created during construction, 500 direct jobs and 1800 indirect jobs (in the catering sector) were created.  Moreover, tens of millions of pounds of income were generated through the spillover effect.",
  benefit_4_example_description: "A socio-economic impact study of the heritage in the city of Mechelen in Belgium has shown that the heritage and its successful conservation are key factors in increasing the quality of life. In one survey of the citizens of Mechelen, 84% of the respondents identified heritage as the largest contributor to the changed image of the city.",

  // Cities 
  potentials: "Potentials",

  city_1_name: "Rijeka",
  city_2_name: "Solin",
  city_3_name: "Ravenna",

  city_1_about: "The unique position of Rijeka—cut deep into the northernmost corner of the Kvarner Gulf, with steep hills behind it—has naturally pushed the city to orient itself towards the sea, sea trade and shipping. The Rijeka sights in the focus of this project are the sights that bear witness to the naval and port as well as the industrial character of this city. Most of these sights are from the 19th and the 20th century, as this was the peak of development of Rijeka.",
  city_2_about: "The river Jadro and the closeness of the Mediterranean determined the history of the Solin area, from the rise of the ancient metropolis of Salona to the technological developments of Solin in the 20th century, largely thanks to its cement factories and quarries. The sights of Solin in the focus of this project bear witness to the development of the city from the ancient times to the industrialization in the 20th century.",

  //City 1 potentials - RIJEKA
  city_1_potential_1_title: "The warehouses",
  city_1_potential_1_description: "The port warehouse complex, built at the beginning of the 20th century in the west part of the port, was nicknamed “the Metropolis” by conservators because it looks like a set from Fritz Lang’s cult classic. Unused warehouses such as the Metropolis can be converted into residential and public spaces, releasing the passive energy of the structure.",
  city_1_potential_1_type: "",

  city_1_potential_2_title: "The Emigranti Hotel",
  city_1_potential_2_description: "The Rijeka–New York line was set up in 1903. To accommodate the migrants in transit, the “Emigranti” hotel was built in the Via Volosca (Ulica Milutina Barača now). Completely renovated, it would be an important sight in Rijeka and could serve its original purpose: the accommodation of migrants, who are arriving in Rijeka in larger numbers every day.",
  city_1_potential_2_type: "",

  city_1_potential_3_title: "The Hartera Paper Factory",
  city_1_potential_3_description: "A study designed for the paper factory recommends developing the Hartera Social-Cultural Center as a combination of three individual but inseparable and interdependent elements: the Parafinka old production hall as a space of creative entrepreneurship, the old residential complex as a space of residential and hotel spaces, and the Marganovo hall as a center of performance art, conferences and administration. (Turato, Križaj Leko)",
  city_1_potential_3_type: "",

  city_1_potential_4_title: "Socialist Heritage",
  city_1_potential_4_description: "In Yugoslavia, the city of Rijeka went through intense urban development thanks to strong national companies such as the Jugolinija shipping line and the “3. Maj” shipyard, companies many citizens of Rijeka are attached to through their family histories. The heritage from this period needs to be explored, interpreted and presented to the public, and without public participation this heritage is impossible to conserve and use.",
  city_1_potential_4_type: "",

  city_1_potential_5_title: "The Industry Museum",
  city_1_potential_5_description: "Although the cultural heritage of Rijeka comes from different periods, the largest part dates from the last few centuries. One could say that the most important heritage of Rijeka is the industrial and port heritage, and that this should be presented in a specialized museum that would occupy the space of one of the unused heritage structures.",
  city_1_potential_5_type: "",

  city_1_potential_6_title: "The Molo Longo Breakwater",
  city_1_potential_6_description: "The position and layout of the port of Rijeka makes the majority of the coast inaccessible from the city center, except for the Rijeka breakwater. Because the breakwater serves a strictly defined purpose, no major construction projects are possible on the structure itself, but some intervention is possible: benches could be installed, the visual design of the breakwater wall could be completed, and interpretation plaques could be installed.",
  city_1_potential_6_type: "",

  //City 2 potentials - SOLIN
  city_2_potential_1_title: "Salona Archaeological Site",
  city_2_potential_1_description: "The ancient Salona was the capital of the Roman province of Dalmatia and its most impressive structure is the amphitheater. The site is now open to visitors and could be used for cultural, tourist and sports events as well as for urban art actions.",
  city_2_potential_1_type: "",

  city_2_potential_2_title: "Gradina",
  city_2_potential_2_description: "A church of an unusual ground plan, built over Roman remains, forms a part of a medieval fortress. In the summer, this is the site of the Solin Cultural Summer Festival, a festival that has itself become a part of Solin tradition.",
  city_2_potential_2_type: "",

  city_2_potential_3_title: "Historic Center of Solin",
  city_2_potential_3_description: "The historic center of Solin is situated on the eastern side of ancient Salona and is one of the most important early medieval centers of Croatian culture. Throughout the whole year it is used for multiple local tourist and cultural events.",
  city_2_potential_3_type: "",

  city_2_potential_4_title: "Vranjic Peninsula",
  city_2_potential_4_description: "Vranjic is a small inhabited peninsula with an active seafront, connected with the mainland via a causeway. Local events are often organized on the streets of this picturesque place, lovingly nicknamed \"Little Venice\" by the locals.",
  city_2_potential_4_type: "",

  city_2_potential_5_title: "Majdan Cultural Center",
  city_2_potential_5_description: "The Center was built in 1954, raising the standard of cultural and social functions in Majdan. A part of the building is now used for sports purposes. However, the square in front of the building could be used for different public events, corresponding to the original purpose of the building.",
  city_2_potential_5_type: "",

  city_2_potential_6_title: "Majdan Factory Settlement",
  city_2_potential_6_description: "The fifth Dalmatian cement factory was built in 1908 in Majdan. There was a great need for workforce housing, so several typologically nearly identical residential buildings were built. The area surrounding them could be used for local events to revitalize the neighborhood.",
  city_2_potential_6_type: "",

  city_2_potential_7_title: "St. Kajo Factory Settlement",
  city_2_potential_7_description: "With the founding of the St. Kajo cement factory and as it later developed, multiple residential buildings were built, alongside some office buildings and even a small cinema that could be renovated and used for local cultural events as well as a space for local associations and clubs.",
  city_2_potential_7_type: "",

  // Footer
  partners: "Partners",
  impressum: "Impressum",
  scroll_to_top: "Back to top",

  // Impressum items
  impressum1: 'Author: Luka Strašek',
  impressum2: 'Texts: Ivana Tokić, Luka Strašek',
  impressum3: 'Animations: Luka Strašek',
  impressum4: 'Photos: Petar Fabijan, Archives of the Conservation Department in Split, City of Solin, Archives of the Maritime and Historical Museum of the Croatian Littoral, Rijeka',
  impressum5: 'Website development: Exevio d.o.o.',
  impressum6: 'Translation and proofreading: Integra d.o.o.',
  impressum7: 'Sources: Center for Industrial Heritage Rijeka, Europa Nostra, www.idisturato.com',
  impressum8: '',
}