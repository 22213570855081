export function videoInViewport(page) {
  const benefitElements = document.querySelectorAll(".benefitEl");
  const cityElements0 = document.querySelectorAll(".cityEl-0");
  const cityElements1 = document.querySelectorAll(".cityEl-1");

  for (let i = 0; i < benefitElements.length; i++) {
    let video = benefitElements[i];

    if (video.tagName !== 'VIDEO') {
      continue
    }
    if (page === 'benefits') {
      video.pause();

      setTimeout(() => {
        video.play();
      }, 300);

      setTimeout(() => {
        video.currentTime = 0;
      }, 500);
    } else {
      video.pause();
      video.currentTime = 0;
    }
  }

  for (let i = 0; i < cityElements0.length; i++) {
    let video = cityElements0[i];

    if (video.tagName !== 'VIDEO') {
      continue
    }
    if (page === 'city-0') {
      video.pause();
      video.currentTime = 0;
      setTimeout(() => {
        video.play();
      }, 300);
    }
    else {
      video.pause();
      video.currentTime = 0;
    }
  }

  for (let i = 0; i < cityElements1.length; i++) {
    let video = cityElements1[i];

    if (video.tagName !== 'VIDEO') {
      continue
    }
    if (page === 'city-1') {
      video.pause();
      video.currentTime = 0;
      setTimeout(() => {
        video.play();
      }, 300);
    }
    else {
      video.pause();
      video.currentTime = 0;
    }
  }
}

export function videoInBenefit(benefitId) {
  const benefitEl = document.querySelectorAll(".benefitEl");

  for (let i = 0; i < benefitEl.length; i++) {
    let video = benefitEl[i];

    if (video.tagName !== 'VIDEO') {
      continue
    }
    if (benefitId === i) {
      video.play();
    }
    else {
      video.pause();
      setTimeout(() => {
        video.currentTime = 0;
      }, 500);
    }
  }
}

export function videoInCity(cityId) {
  const cityElements0 = document.querySelectorAll(".cityEl-0");
  const cityElements1 = document.querySelectorAll(".cityEl-1");

  for (let i = 0; i < cityElements0.length; i++) {
    let video = cityElements0[i];

    if (video.tagName !== 'VIDEO') {
      continue
    }
    if (cityId === i) {
      video.play();
    }
    else {
      video.pause();
      setTimeout(() => {
        video.currentTime = 0;
      }, 500);
    }

  }

  for (let i = 0; i < cityElements1.length; i++) {
    let video = cityElements1[i];

    if (video.tagName !== 'VIDEO') {
      continue
    }
    if (cityId === i) {
      video.play();
    }
    else {
      video.pause();
      setTimeout(() => {
        video.currentTime = 0;
      }, 500);
    }
  }
}