<template>
  <div class="select-wrap">
    <select
      v-model="selectedLanguage"
      name="lang"
      id="lang"
      class="form-select custom-picker language-picker"
    >
      <option value="hr">HR</option>
      <option value="en">EN</option>
      <option value="it">IT</option>
    </select>
    <img :src="arrow" class="chevron-down" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedLanguage: "hr",
      arrow: require("@/assets/icons/down-arrow.svg"),
    };
  },

  watch: {
    selectedLanguage: {
      handler() {
        this.changeLang();
      },
    },
  },

  mounted() {
    if (localStorage.lang) {
      this.selectedLanguage = localStorage.lang;
    }
  },

  methods: {
    changeLang() {
      this.$i18n.locale = this.selectedLanguage;
      localStorage.setItem("lang", this.$i18n.locale);
    },
  },
};
</script>