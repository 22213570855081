<template>
  <div id="app" ref="app">
    <Header
      :selectedCityId="selectedCityId"
      @selectedCityChanged="changeSelectedCity"
      @activePageChanged="changeActivePage"
    />

    <div class="scroll-wrap">
      <Dots :activePageId="activePageId" class="d-none d-lg-block d-xl-block" />

      <Home
        class="scroll-item"
        :observer="observer"
        @activePageChanged="changeActivePage"
      />

      <Benefits
        class="scroll-item"
        :observer="observer"
        :activePageId="activePageId"
      />

      <Cities
        :isMobile="isMobile"
        :observer="observer"
        :activePageId="activePageId"
      />

      <Footer
        class="scroll-item"
        :observer="observer"
        @activePageChanged="changeActivePage"
      />
    </div>
  </div>
</template>

<script>
import Benefits from "./components/benefits/Benefits.vue";
import Cities from "./components/city/Cities.vue";
import Dots from "./components/Dots.vue";
import Footer from "./components/Footer.vue";
import Header from "./components/header/Header.vue";
import Home from "./components/Home.vue";
import { videoInViewport } from "./video.js";

export default {
  name: "App",

  components: {
    Benefits,
    Cities,
    Dots,
    Footer,
    Header,
    Home,
  },

  data() {
    return {
      observer: null,
      activePageId: null,
      selectedCityId: null,
      showNavbar: true,
      lastScrollPosition: 0,
    };
  },

  created() {
    this.observer = new IntersectionObserver(this.onElementObserved, {
      root: this.$refs.app,
      threshold: 0.8,
    });
  },

  beforeDestroy() {
    this.observer.disconnect();
  },

  computed: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    activePageId() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);

      window.addEventListener("touchstart", () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });

      window.addEventListener("resize", () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      });

      videoInViewport(this.activePageId);
    },
  },

  methods: {
    // Change selected city trough dropdown
    changeSelectedCity(cityId) {
      this.changeActivePage(`city-${cityId}`);
    },

    changeActivePage(pageId) {
      document
        .getElementById(pageId)
        .scrollIntoView({ block: "center", behavior: "smooth" });

      setTimeout(() => {
        this.activePageId = pageId;
      }, 1000);
    },

    // Intersecton observer that recognizes the active page in viewport
    onElementObserved(entries) {
      entries.forEach(({ target, isIntersecting }) => {
        if (!isIntersecting) {
          return;
        }

        setTimeout(() => {
          let targetId = target.id;

          targetId = targetId.includes("wrapper-city")
            ? targetId.split("wrapper-")[1]
            : targetId;

          this.activePageId = targetId;

          // If active page is city page, change the active city - dynamically change the selected city in dropdown
          if (targetId.includes("city")) {
            const id = targetId.substring(
              targetId.lastIndexOf("-") + 1,
              targetId.length
            );
            id == 0
              ? (this.selectedCityId = 0)
              : (this.selectedCityId = parseInt(id));
          } else this.selectedCityId = null;
        });
      });
    },
  },
};
</script>