<template>
  <div class="header">
    <div class="header__container d-flex">
      <div class="logo me-auto pointer" @click="scrollToTop">
        <img class="tempus-logo" :src="logo" alt="logo" />
      </div>

      <CityPicker
        class="me-2"
        :selectedCityId="selectedCityId"
        @selectedCityChanged="changeSelectedCity"
      />

      <LanguagePicker />
    </div>
  </div>
</template>

<script>
import CityPicker from "./CityPicker.vue";
import LanguagePicker from "./LanguagePicker.vue";

export default {
  props: {
    selectedCityId: {
      typeof: Number,
      default: null,
    },
  },

  components: {
    CityPicker,
    LanguagePicker,
  },

  data() {
    return {
      logo: require("@/assets/logo/logo.svg"),
    };
  },

  methods: {
    changeSelectedCity(cityId) {
      if (cityId != null) this.$emit("selectedCityChanged", cityId);
    },

    scrollToTop() {
      this.$emit("activePageChanged", "home");
    },
  },
};
</script>

<style>
</style>