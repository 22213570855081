<template>
  <div class="controls w-100">
    <div class="pt-4">
      <div class="row align-items-end">
        <div class="col d-flex city__arrows" v-if="manualPagination">
          <div
            class="prev pointer"
            @click="changeActivePotential(activePotential - 1)"
          >
            <img class="icon" :src="require('@/assets/icons/previous.svg')" />

            <p class="control-text button-text">
              {{ $t("previous") }}
            </p>
          </div>

          <div class="desktop-icons justify-content-center">
            <!-- <div
              class="circle icon icon--animate"
              :class="[
                activePotential === 0 ? circleVisible : circleOpacity,
                'pointer',
                'pe-2',
                'toggle-icon',
              ]"
              @click="changeActivePotential(0)"
            ></div> -->

            <div
              class="circle icon icon--animate"
              :class="[
                activePotential === potential.id
                  ? circleVisible
                  : circleOpacity,
                'pointer',
                'pe-2',
                'toggle-icon',
              ]"
              v-for="potential in potentials"
              :key="potential.id"
              @click="changeActivePotential(potential.id)"
            ></div>
          </div>

          <div
            class="next pointer"
            @click="changeActivePotential(activePotential + 1)"
          >
            <img class="icon" :src="require('@/assets/icons/next.svg')" />

            <p class="control-text button-text">
              {{ $t("next") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    potentials: {
      typeof: Array,
      default() {
        return [];
      },
    },

    activePotential: {
      typeof: Number,
    },

    manualPagination: {
      typeof: Boolean,
      default: true,
    },
  },

  data() {
    return {
      grayIcon: "gray-icon",
      circleVisible: "circle--visible",
      circleOpacity: "circle--opacity",
    };
  },

  methods: {
    changeActivePotential(potentialId) {
      if (potentialId < 0) potentialId = this.potentials.length - 1;
      else if (potentialId >= this.potentials.length) potentialId = 0;

      this.$emit("activePotentialChanged", potentialId);
    },
  },
};
</script>