<template>
  <div
    id="home"
    class="
      home
      container-fluid
      d-flex
      flex-column
      justify-content-start justify-content-lg-center
      align-items-center
      text-center
    "
  >
    <div>
      <div class="pb-3">
        <img :src="logo" width="60" alt="logo" />
      </div>

      <h1 class="title text-uppercase ls-15 display-5">
        {{ $t("title") }}
      </h1>

      <div>
        <img :src="separator" width="50" alt="logo" />
      </div>

      <p class="description pt-2">
        {{ $t("description") }}
      </p>
    </div>

    <img
      class="icon icon--readmore pointer"
      :src="readMore"
      alt="Read more"
      @click="changeActivePage"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require("@/assets/logo/logo.svg"),
      separator: require("@/assets/icons/separator.svg"),
      readMore: require("@/assets/icons/read_more.svg"),
    };
  },

  props: {
    observer: null,
  },

  mounted() {
    this.observer.observe(this.$el);
  },

  methods: {
    changeActivePage() {
      this.$emit("activePageChanged", "benefits");
    },
  },
};
</script>