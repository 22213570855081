const cities = [
  {
    id: 0,
    path: "rijeka",
    potentials: [
      {
        id: 0,
        picture: "rijeka.mp4",
        translation_key_title: "city_1_name",
        translation_key_description: "city_1_about",
      },
      {
        id: 1,
        picture: "potential1.jpg",
        translation_key_title: "city_1_potential_1_title",
        translation_key_description: "city_1_potential_1_description",
      },
      {
        id: 2,
        picture: "potential2.jpg",
        translation_key_title: "city_1_potential_2_title",
        translation_key_description: "city_1_potential_2_description",
      },
      {
        id: 3,
        picture: "potential3.jpg",
        translation_key_title: "city_1_potential_3_title",
        translation_key_description: "city_1_potential_3_description",
      },
      {
        id: 4,
        picture: "potential4.mp4",
        translation_key_title: "city_1_potential_4_title",
        translation_key_description: "city_1_potential_4_description",
      },
      {
        id: 5,
        picture: "potential5.jpg",
        translation_key_title: "city_1_potential_5_title",
        translation_key_description: "city_1_potential_5_description",
      },
      {
        id: 6,
        picture: "potential6.jpg",
        translation_key_title: "city_1_potential_6_title",
        translation_key_description: "city_1_potential_6_description",
      },
    ],
  },
  {
    id: 1,
    path: "solin",
    potentials: [
      {
        id: 0,
        picture: "solin.mp4",
        translation_key_title: "city_2_name",
        translation_key_description: "city_2_about",
      },
      {
        id: 1,
        picture: "potential1.jpg",
        translation_key_title: "city_2_potential_1_title",
        translation_key_description: "city_2_potential_1_description",
      },
      {
        id: 2,
        picture: "potential2.jpg",
        translation_key_title: "city_2_potential_2_title",
        translation_key_description: "city_2_potential_2_description",
      },
      {
        id: 3,
        picture: "potential3.mp4",
        translation_key_title: "city_2_potential_3_title",
        translation_key_description: "city_2_potential_3_description",
      },
      {
        id: 4,
        picture: "potential4.jpg",
        translation_key_title: "city_2_potential_4_title",
        translation_key_description: "city_2_potential_4_description",
      },
      {
        id: 5,
        picture: "potential5.jpg",
        translation_key_title: "city_2_potential_5_title",
        translation_key_description: "city_2_potential_5_description",
      },
      {
        id: 6,
        picture: "potential6.jpg",
        translation_key_title: "city_2_potential_6_title",
        translation_key_description: "city_2_potential_6_description",
      },
      {
        id: 7,
        picture: "potential7.jpg",
        translation_key_title: "city_2_potential_7_title",
        translation_key_description: "city_2_potential_7_description",
      },
    ],
  },
  // {
  //   id: 2,
  //   path: "ravenna",
  //   potentials: [
  //     {
  //       id: 0,
  //       picture: "rijeka.mp4",
  //       translation_key_title: "city_3_name",
  //       translation_key_description: "city_3_about",
  //     },
  //     {
  //       id: 1,
  //       picture: "potential1.jpg",
  //       translation_key_title: "city_3_potential_1_title",
  //       translation_key_description: "city_3_potential_1_description",
  //     },
  //     {
  //       id: 2,
  //       picture: "potential2.jpg",
  //       translation_key_title: "city_3_potential_2_title",
  //       translation_key_description: "city_3_potential_2_description",
  //     },
  //     {
  //       id: 3,
  //       picture: "potential3.jpg",
  //       translation_key_title: "city_3_potential_3_title",
  //       translation_key_description: "city_3_potential_3_description",
  //     },
  //     {
  //       id: 4,
  //       picture: "potential4.jpg",
  //       translation_key_title: "city_3_potential_4_title",
  //       translation_key_description: "city_3_potential_4_description",
  //     },
  //   ],
  // },
]

export default cities