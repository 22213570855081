<template>
  <div class="select-wrap">
    <select
      v-model="selectedCityIdValue"
      name="city"
      id="city"
      class="form-select custom-picker cities-picker text-uppercase"
    >
      <option :value="null" disabled selected hidden class="option">
        {{ $t("cities") }}
      </option>
      <option
        v-for="city in cities"
        :key="city.id"
        :value="city.id"
        class="option"
      >
        {{ $t(city.potentials[0].translation_key_title) }}
      </option>
    </select>
    <img :src="arrow" class="chevron-down" />
  </div>
</template>

<script>
import cities from "../../assets/cities.js";

export default {
  props: {
    selectedCityId: {
      typeof: Number,
      default: null,
    },
  },

  data() {
    return {
      cities,
      selectedCityIdValue: null,
      arrow: require("@/assets/icons/down-arrow.svg"),
    };
  },

  watch: {
    selectedCityId: {
      handler() {
        this.selectedCityIdValue = this.selectedCityId;
      },
    },

    selectedCityIdValue: {
      handler() {
        if (this.selectedCityIdValue != this.selectedCityId)
          this.$emit("selectedCityChanged", this.selectedCityIdValue);
      },
    },
  },
};
</script>