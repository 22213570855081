<template>
  <div :class="['justify-content-between', 'controls', 'd-flex']">
    <div class="prev pointer" @click="changeActiveBenefit(activeBenefit - 1)">
      <img class="white-icon icon" :src="previous" alt="Previous" />

      <p class="text">
        {{ $t("previous") }}
      </p>
    </div>

    <div class="desktop-icons">
      <div
        v-for="benefit in benefits"
        :key="benefit.id"
        @click="changeActiveBenefit(benefit.id)"
      >
        <div
          class="circle icon icon--animate"
          :class="[
            activeBenefit === benefit.id ? circleVisible : circleOpacity,
            'pointer',
            'pe-2',
            'toggle-icon',
            'problematic-element',
          ]"
        />
      </div>
    </div>

    <div class="next pointer" @click="changeActiveBenefit(activeBenefit + 1)">
      <img class="white-icon icon" :src="next" alt="Next" />

      <p class="text">
        {{ $t("next") }}
      </p>
    </div>
  </div>
</template>

<script>
import { videoInViewport } from "../../video.js";

export default {
  props: {
    benefits: {
      typeof: Object,
      default: undefined,
    },

    activeBenefit: {
      typeof: Number,
    },

    manualPagination: {
      typeof: Boolean,
      default: true,
    },

    activePageId: {
      typeof: String,
      default: "",
    },
  },

  data() {
    return {
      whiteIcon: "white-icon",
      grayIcon: "gray-icon",
      pointer: "pointer",
      disabled: "disabled",
      circleVisible: "circle--visible",
      circleOpacity: "circle--opacity",

      previous: require("@/assets/icons/previous.svg"),
      next: require("@/assets/icons/next.svg"),
    };
  },

  methods: {
    changeActiveBenefit(benefitId) {
      if (benefitId < 0) benefitId = this.benefits.length - 1;
      else if (benefitId >= this.benefits.length) benefitId = 0;

      videoInViewport(this.activePageId);

      this.$emit("activeBenefitChanged", benefitId);
    },
  },
};
</script>

<style>
.problematic-element {
  display: inline-block;
  min-width: 0%;
  transform: translateZ(0);
}

@media (max-width: 600px) {
  .controls {
    bottom: 20px !important;
  }
}
</style>