<template>
  <div class="city scroll-item">
    <CityPotentialScreen
      v-for="potential in city.potentials"
      :key="potential.id"
      :cityName="city.path"
      :potential="potential"
      :activePotential="activePotential"
      :potentials="city.potentials"
      @activePotentialChanged="changeActivePotential"
      :cityId="city.id"
    />
  </div>
</template>

<script>
import CityPotentialScreen from "./CityPotentialScreen.vue";
import { videoInCity } from "../../video.js";

export default {
  props: {
    city: {
      typeof: Object,
      default: undefined,
    },
    activePageId: {
      typeof: String,
      default: "",
    },
    observer: null,
  },

  components: {
    CityPotentialScreen,
  },

  data() {
    return {
      activePotential: 0,
      preloadedImages: [],
    };
  },

  mounted() {
    this.observer.observe(this.$el.parentElement);

    for (const potential of this.city.potentials) {
      let image = new Image();
      image.src = require(`@/assets/cities/${this.city.path}/${potential.picture}`);

      this.preloadedImages.push(image);
    }
  },

  methods: {
    changeActivePotential(potentialId) {
      this.activePotential = potentialId;

      videoInCity(this.activePotential);
    },
  },
};
</script>

<style>
.potential-hidden {
  opacity: 0;
  pointer-events: none;
}

.potential-visible {
  pointer-events: all;
}

.potential-animation {
  position: absolute;
  transition: opacity 0.5s linear;
}
</style>