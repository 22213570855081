var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['justify-content-between', 'controls', 'd-flex']},[_c('div',{staticClass:"prev pointer",on:{"click":function($event){return _vm.changeActiveBenefit(_vm.activeBenefit - 1)}}},[_c('img',{staticClass:"white-icon icon",attrs:{"src":_vm.previous,"alt":"Previous"}}),_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("previous"))+" ")])]),_c('div',{staticClass:"desktop-icons"},_vm._l((_vm.benefits),function(benefit){return _c('div',{key:benefit.id,on:{"click":function($event){return _vm.changeActiveBenefit(benefit.id)}}},[_c('div',{staticClass:"circle icon icon--animate",class:[
          _vm.activeBenefit === benefit.id ? _vm.circleVisible : _vm.circleOpacity,
          'pointer',
          'pe-2',
          'toggle-icon',
          'problematic-element',
        ]})])}),0),_c('div',{staticClass:"next pointer",on:{"click":function($event){return _vm.changeActiveBenefit(_vm.activeBenefit + 1)}}},[_c('img',{staticClass:"white-icon icon",attrs:{"src":_vm.next,"alt":"Next"}}),_c('p',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("next"))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }