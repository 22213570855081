<template>
  <div v-if="benefit.id === activeBenefit">
    <h1 class="main-title text-uppercase text-center">
      {{ $t("benefit_home_title") }}
    </h1>
    <div
      class="
        d-flex
        justify-content-between
        align-items-end
        pt-3 pt-lg-5
        position-relative
        flex-sm-column
        justify-content-sm-start
        align-items-sm-start
      "
    >
      <h2 class="title pt-4 order-sm-1 display-5">
        {{ $t(benefit.translation_key_example_title) }}
      </h2>
    </div>

    <p
      class="description pt-4"
      v-html="$t(benefit.translation_key_example_description)"
    ></p>
  </div>
</template>

<script>
export default {
  props: {
    benefit: {
      typeof: Object,
      default: undefined,
    },

    activeBenefit: {
      typeof: Number,
      default: 0,
    },
  },

  data() {
    return {};
  },
};
</script>
