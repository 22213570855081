import Vue from 'vue'
import App from './App.vue'
import { i18n } from '@/plugins/i18n'
import 'bootstrap/dist/css/bootstrap.css'
import './style/style.scss'
import { elementScrollIntoViewPolyfill } from "seamless-scroll-polyfill";
elementScrollIntoViewPolyfill();

Vue.config.productionTip = false

new Vue({
  i18n,
  render: h => h(App),
}).$mount('#app')

